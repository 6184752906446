.breadcrumbs {
  display: flex;
  align-items: center;
  .breadcrumbs-item, .breadcrumbs-item-active {
    padding: 0 7px;
  }

  .breadcrumbs-item:first-child {
    padding-left: 0;
  }
}

.breadcrumbs-item {

}

.breadcrumbs-item-active {

}

.breadcrumbs-delimiter {
  font-size: $h4-font-size;
}
