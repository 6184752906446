.customization-page-icon{
  background: transparent;
  width: 100%;
  height: auto;
}

.widget-items-container {
  user-select: none;
  padding: 10px;
  background-color: #d3d3d338;
  border: 1px solid lightgrey;
  border-bottom: 0;
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.widget-item-add {
  cursor: pointer;
  padding: 20px 52px;
  background: white;
  border: 1px solid lightgrey;
  //color: grey;
}

.widget-edit-section {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-top: 0;
  }
}

.widget-edit-section-title {
  font-size: $h3-font-size;
}

.widget-items-container:last-child {
  border-bottom: 1px solid lightgrey;
}

.widget-items-add-icon {
  flex-grow: 0.2;
  color: #3f4eae;
}

.widget-item-title {
  font-size: $font-size-base;
}

.widget-items-title {
  flex-grow: 20;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.widget-item-header {
  font-size: $lead-font-size
}

.widget-item-sub-header {
  font-size: $font-size-sm
}
