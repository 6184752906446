@import "settings";
@import "dashboard";
@import "date-picker";
@import "analytics";
@import "recs";
@import "organization-billing";
@import "store-catalog";
@import "breadcrumbs";
@import "logo";
@import "placeholders";
@import "experiment";

@import "single-name-styles";

.nav-disabled-item {
  pointer-events: none;
  opacity: 0.5;
}
