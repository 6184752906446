/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.app-wrapper {
  padding: 30px;
  max-width: 1200px;
  margin: auto;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}

.app-wrapper-full-width {
  padding: 30px;
  margin: auto;

  @media screen and (max-width: 575px) {
    padding: 10px;
  }
}
