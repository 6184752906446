.analytic-widget-show-more-wrapper{
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.widgets-performance {
  .pages-container {
    .page-block {
      .div_separator {
        border-bottom: 1px solid #dddddd;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      &:last-child .div_separator {
        border-bottom: 0;
      }
    }
  }
  .widget-block {
    padding: 15px 0 15px;
    .widget-body {
    }
    .table-container {
      display:flex;
      align-items:center;
      .data-table {
      }
    }
    .main-value-container {
      display:flex;
      align-items:center;
      .main-value {
        font-size: 40px;
      }
    }
  }
}