.exp-main-accent {
  padding-top: 20px;
  padding-bottom: 20px;
}

.exp-main-accent-elevation {
  padding-top: 12px;
}

.exp-row-header {
  padding: 20px 0 20px 15px;
  font-weight: bold;
}

.exp-row-subheader {
  height: 100%;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exp-row-value {
  //max-width: 150px;
  padding: 7px 0 7px;
  height: 100%;
  width: 100%;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.exp-row-elevation-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 200px;
}

.exp-row-elevation-item-good {
  color: green;
  svg {
    fill: green;
  }
}

.exp-row-elevation-item-bad {
  color: red;
  svg {
    fill: red;
  }
}

.exp-row-elevation-icon {
  height: 16px;
  width: auto;
  margin-top: -4px;
  fill: rgb(87, 87, 87) ;
}
