.placeholder-error {
  margin: auto;
  opacity: 0.3;
  padding-top: 100px;
  width: 500px;
}


.placeholder-no-data{
  margin: auto;
  opacity: 0.3;

  img{
    width: 100%;
    max-width: 340px;
  }
}
