.dashboard-summary-icon {
  fill: $secondary;
  height: 50px;
  width: 80px;
}

.card-container-progress {
  width: calc(100% + 60px) !important;
  left: -30px !important;
  top: -30px !important;
}

.analytic-table-progress-row {
  width: calc(100% - 59px);
  background: #fffae2;
  position: absolute;
  transform: scaleX(0);
  opacity: 0.3;
  transition: opacity .25s ease-out, transform .25s linear;
  will-change: transform,opacity;
  transform-origin: left;
  height: 32px;
}

.noDataAvailableChart {
  width: 100%;
  height: 100%;
  padding: 50px;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  opacity: 0.3;
}

.textSegment {
  font-size: $h2-font-size;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin-top: 25px;
}

.donutSegment {
  //max-width: 300px;
  //margin: auto auto 19px;
}

.noDataAvailableChart {
  width: 100%;
  height: 100%;
  padding: 50px;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  opacity: 0.3;
}

.textEfficiencyHeader {
  font-size: $font-size-base;
  font-weight: bold !important;
  margin: 0 0 10px;
}

.textEfficiencySubHeader {
  font-size: $font-size-base;
  padding-left: 25px;
}

.textEfficiencyGroupNo {
  text-align: center;
  /*color: #00848E;*/
}

.textEfficiencyGroupKea {
  text-align: center;
  color: #108043;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

table.chartTable {
  width: 100%;
}

table.chartTable > tbody > tr > td {
  padding: 8px;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.chartHeader {
  font-size: $h2-font-size !important;
  font-weight: bold;
  text-align: left !important;
  //color: #1c2260 !important;
  padding-bottom: 15px !important;
}

p.noteText {
  font-size: $font-size-base !important;
  //color: #4A1504 !important;
}
