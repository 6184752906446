.alerts-banner-wrapper {
  width: calc(100% - 20px);
  max-width: 700px;
  z-index: 9;
  top: 80px;
  position: absolute;
  transform: translateX(0px);
  right: 20px;
}

.settings-container {
  margin: 0 auto;
  width: 800px;
  padding: 0 35px 20px;

  @media (max-width: 576px) {
    padding: 0 10px;
  }
}

.field-weight-title {
  align-items: center;
  display: flex;
}

.setting-item-container {
  font-size: $font-size-base;
  padding: 20px 0;
  width: 100%;
  & + & {
    border-top: 1px solid rgba(0, 0, 0, .06);
  }
}

.setting-group-sub-header {
  padding-bottom: 10px;
  font-size: $font-size-base;
}

.setting-group-header {
  padding-top: 32px;
  //color: rgb(90, 90, 90);
  font-weight: bold;
}

.setting-group-note {
  font-size: $font-size-base;
}

.setting-item-title {
  font-size: $font-size-base;
}

.setting-item-description {
  font-weight: 400;
  font-size: $font-size-base;
}

.setting-btn-grp-button{
  width: 100px;
}

.setting-slider-input{
  width: 100px;
}

.setting-item-fetching-circular{
  height: 20px;
}

.centered {
  text-align: center;
}

.header-save-actions{
  //width: 200px;
  text-align: center;

  .loader {
    width: 45px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -14px;
    margin-left: -23px;
  }
}
