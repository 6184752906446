
.full-height-card{
  height: calc(100% - 30px);
}

.min-height-420px{
  min-height: 420px;
}

.width220{
  width: 220px;
}

.min-height-450px{
  min-height: 450px;
}

.min-height-510px{
  min-height: 510px;
}

.min-height-600px{
  min-height: 600px;
}

.min-height-700px{
  min-height: 700px;
}

.max-height-450px{
  max-height: 450px;
}

.max-height-500px{
  max-height: 500px;
}

.fullHeight{
  height: 100%;
}

.fullWidth{
  width: 100%;
}

.noMargin{
  margin: 0!important;
}

.noPadding{
  padding: 0!important;
}

.padding10px{
  padding: 10px;
}

.noTextDecoration{
  text-decoration: none;
}
