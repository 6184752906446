@import 'src/styles/global/variables';

.image {
  max-height: 90px !important;
  width: auto;
}

.card {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 110px;
  border: 1px solid transparent;
  transition: 200ms all;
  &:hover {
    border: 1px solid rgba($secondary, .6);
  }
}

.card-active {
  border: 1px solid $secondary;
  background-color: rgba($secondary, .05) !important;
}

.content {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  p {
    padding: 0!important;
  }
}
