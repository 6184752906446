.subheader1 {
    color: #666666!important;
    font-size: 20px!important;
    line-height: 1.5!important;
    font-weight: 300!important;
    padding-bottom: 20px!important;
}

.subheader2 {
  color: #666666!important;
  font-size: 20px!important;
  padding-bottom: 30px!important;
}