.kealabs-logo {
  flex-basis: 32%;
  height: 53px;
  width: 80px;
}

.user-detail {
  flex-basis: 68%;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  text-align: center;
}

.user-profile {
  justify-content: space-between;
}
