.sliderText {
  font-size: 30px !important;
}

.sliderWrapper {
  display: flex;
  height: 500px;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}