.buttons {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
}

.page {
  //zoom: 1.6;
  height: 610px;
}