.plan-container {
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 0;

  .plan-item {
    border-bottom: 1px dashed lightgrey;

    .plan-price-section{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .plan-items-section{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

    }

    .plan-btn-section{
      background-color: #80808010;
      min-height: 100px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button{
        width: 60%;
      }
    }
   }

  .plan-item:last-child {
    border-bottom: none;
  }
}

