.header1 {
  color: #1c2260!important;
  font-size: 32px!important;
  padding-top: 40px!important;
  padding-bottom: 20px!important;
}

.header2 {
  color: #1c2260!important;
  font-size: 20px!important;
  padding-bottom: 30px!important;
}